::-webkit-scrollbar {
  display: flex;
  width: 5px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  border-radius: 2px;
}

.chatlist:hover ::-webkit-scrollbar {
  display: block;
  transition: all 5s ease-out;
}
